export default {
  noItemsSurtitle: {
    id: 'cart_no_items_surtitle',
    defaultMessage: 'Buzzkill alert',
  },
  noItemsTitle: {
    id: 'cart_no_items_title',
    defaultMessage: "There's nothing in your cart.",
  },
  noItemsDescription: {
    id: 'cart_no_items_description',
    defaultMessage: "All this stellar refurb isn't gonna shop itself!'",
  },
  noItemsButton: {
    id: 'cart_no_items_button',
    defaultMessage: 'Browse sweet deals',
  },
  buttonCheckout: {
    id: 'recap_button',
    defaultMessage: 'Checkout',
  },
  securePayment: {
    id: 'cart_secure_payment',
    defaultMessage: 'Secure payment with Adyen',
  },
  mainTitle: {
    id: 'main_title',
    defaultMessage: 'Your cart',
  },
  productGrade: {
    id: 'product_grade',
    defaultMessage: 'Esthetic condition:',
  },
  productGradeWithNewBattery: {
    id: 'product_grade_with_new_battery',
    defaultMessage: '{grade} with new battery',
  },
  cartColor: {
    id: 'funnel_cart_color',
    defaultMessage: 'Color :',
  },
  productRemove: {
    id: 'product_remove',
    defaultMessage: 'Remove',
  },
  trustpackCGVError: {
    id: 'trutpack_cgv_error',
    defaultMessage: 'Thanks for accepting the general buying conditions.',
  },
  swapMultipleItemsTitle: {
    id: 'funnel_swap_multiple_items_title',
    defaultMessage: 'Cart',
  },
  crossSellCategory: {
    id: 'funnel_cart_cross_sell_category',
    defaultMessage: 'Cases',
  },
  titleCrossSell: {
    id: 'funnel_cart_cross_sell_title',
    defaultMessage: 'In case you need them',
  },
  chooseCollectionPointButton: {
    id: 'funnel_cart_page_choose_collection_point_button',
    defaultMessage: 'Choose pickup location',
  },
  editCollectionPointButton: {
    id: 'funnel_cart_page_edit_collection_point_button',
    defaultMessage: 'Edit pickup location',
  },
  mandatoryCollectionPoint: {
    id: 'funnel_cart_page_mandatory_collection_point',
    defaultMessage:
      'Please choose a pickup location or select another shipping method.',
  },
  mandatoryInsurance: {
    id: 'funnel_cart_page_mandatory_insurance',
    defaultMessage: 'Please choose a coverage option.',
  },
  youSave: {
    id: 'funnel_cart_page_you_save',
    defaultMessage: 'You save {reduction} vs. new',
  },
  warranty: {
    id: 'funnel_cart_page_warranty',
    defaultMessage: '1 year warranty',
  },
  insurancePromocodeBanner: {
    id: 'insurance_promocode_banner',
    defaultMessage:
      'Get 25$ off with promo code BackMeUp (and peace of mind) when you bundle your device with a protection plan (minimum order value : 250$).',
  },
  insurancePromocodeBannerAriaLabel: {
    id: 'insurance_promocode_banner_close_button_aria_label',
    defaultMessage: 'Close banner',
  },
  mobilePlanRemovalSuccessfulTitle: {
    id: 'funnel_cart_mobile_plan_removal_successful_title',
    defaultMessage: 'Mobile plan removed',
  },
  mobilePlanRemovalSuccessfulContent: {
    id: 'funnel_cart_mobile_plan_removal_successful_content',
    defaultMessage: 'The price of your order has been refreshed',
  },
  bundlingDiscount: {
    id: 'funnel_cart_bundle_discount',
    defaultMessage: 'Bundling saved you {price}',
  },
  originalPriceDisplay: {
    id: 'product_page_add_to_cart_original_price_display',
    defaultMessage: '{price} new',
  },
  youSaveDisplay: {
    id: 'product_page_you_save_amount_display',
    defaultMessage: 'Save {reduction}',
  },
}

<template>
  <div :data-qa="`cart-shipping-option-${listingId}-${shippingId}`">
    <RevRadio
      :id="`cart-shipping-option-${listingId}-${shippingId}`"
      :key="optionKey"
      :image="icon"
      :model-value="checked"
      :value="shippingId"
      variant="full"
      @change="$emit('change')"
    >
      <template #label>
        <div class="flex flex-col md:block">
          <span class="body-1-bold mb-8 md:mb-0">
            {{
              i18n(translations.arrivalDates, {
                earliest: earliestArrivalShortDate,
                latest: latestArrivalShortDate,
                price,
              })
            }}
          </span>
          <span class="body-1 ml-8">
            {{ i18n(description, { shipper }) }}
          </span>
        </div>
      </template>

      <template #description v-if="shouldDisplayCollectionPointAddress">
        <div class="body-2-bold" data-qa="collection-point-name">
          {{ selectedCollectionPoint.name }}
        </div>
        <div class="body-2 mt-4" data-qa="collection-point-address">
          {{ selectedCollectionPointAddress }}
        </div>
      </template>
    </RevRadio>

    <div
      v-if="shouldDisplayCollectionPointError"
      class="text-action-danger-hi body-2-bold mt-12"
    >
      {{ i18n(translations.mandatoryCollectionPoint) }}
    </div>

    <CollectionPointsModal
      v-if="shouldDisplayCollectionPointButton"
      :listing-id="listingId"
      :shipping-id="shippingId"
    >
      <template #trigger="{ open }">
        <div class="mt-12 flex justify-end">
          <RevButtonTiny
            data-qa="collection-points-button"
            :icon="collectionPointButtonIcon"
            type="button"
            variant="secondary"
            @click="handleCollectionPointButtonClick(open)"
          >
            {{ i18n(collectionPointButtonLabel) }}
          </RevButtonTiny>
        </div>
      </template>
    </CollectionPointsModal>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Address } from '@backmarket/nuxt-module-address/address'
import { useInlineFormatAddress } from '@backmarket/nuxt-module-address/useInlineFormatAddress'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevRadio } from '@ds/components/Radio'
import { IconEdit } from '@ds/icons/IconEdit'
import { IconHome } from '@ds/icons/IconHome'
import { IconLocationPin } from '@ds/icons/IconLocationPin'
import { IconPackage } from '@ds/icons/IconPackage'

import { useCartStore } from '../stores/cartStore'

import CollectionPointsModal from './CollectionPoints/CollectionPointsModal.vue'
import translations from './ShippingOption.translations'

const props = withDefaults(
  defineProps<{
    checked?: boolean
    earliestArrivalDate?: string
    isCollectionPoint?: boolean | undefined
    latestArrivalDate?: string
    listingId: string
    price?: string
    shipper?: string | undefined
    shippingId: string
    showCollectionPointError: boolean
  }>(),
  {
    checked: false,
    isCollectionPoint: false,
    earliestArrivalDate: '',
    latestArrivalDate: '',
    price: '',
    shipper: '',
  },
)

defineEmits(['change'])

const cartStore = useCartStore()
const i18n = useI18n()
const tracking = useTracking()

const optionKey = computed(() => {
  const collectionPointKey = cartStore.hasSelectedCollectionPoint
    ? cartStore.selectedCollectionPoint.name
    : ''

  return `cart-shipping-option-${props.listingId}-${props.shippingId}-${collectionPointKey}`
})

const description = computed(() =>
  props.isCollectionPoint
    ? translations.descriptionCollectionPoint
    : translations.descriptionRegular,
)

const icon = computed(() =>
  props.isCollectionPoint ? IconLocationPin : IconHome,
)

const collectionPointButtonLabel = computed(() =>
  cartStore.hasSelectedCollectionPoint
    ? translations.editCollectionPointButton
    : translations.chooseCollectionPointButton,
)

const collectionPointButtonIcon = computed(() =>
  cartStore.hasSelectedCollectionPoint ? IconEdit : IconPackage,
)

const selectedCollectionPoint = computed(
  () => cartStore.selectedCollectionPoint,
)

const selectedCollectionPointAddress = computed(() =>
  useInlineFormatAddress(
    cartStore.selectedCollectionPoint.address as unknown as Address,
    {
      includeCompany: false,
      includeCountry: false,
    },
  ),
)

const shouldDisplayCollectionPointAddress = computed(
  () =>
    props.checked &&
    props.isCollectionPoint &&
    cartStore.hasSelectedCollectionPoint,
)

const shouldDisplayCollectionPointButton = computed(
  () => props.checked && props.isCollectionPoint,
)

const shouldDisplayCollectionPointError = computed(
  () =>
    props.checked && props.isCollectionPoint && props.showCollectionPointError,
)

const earliestArrivalShortDate = computed(() =>
  i18n.date(new Date(props.earliestArrivalDate), {
    day: 'numeric',
    month: 'short',
  }),
)

const latestArrivalShortDate = computed(() =>
  i18n.date(new Date(props.latestArrivalDate), {
    day: 'numeric',
    month: 'short',
  }),
)

const handleCollectionPointButtonClick = (open: () => void) => {
  open()

  tracking.trackClick({
    zone: 'cart',
    name: cartStore.hasSelectedCollectionPoint
      ? 'edit_collection_point'
      : 'choose_collection_point',
  })
}
</script>

export default {
  stickyButtonTotal: {
    id: 'go_to_next_sticky_button_recap_total',
    defaultMessage: 'Cart total',
  },
  button: {
    id: 'recap_button',
    defaultMessage: 'Checkout',
  },
  checkoutButton: {
    id: 'checkout_summary_button_checkout',
    defaultMessage: 'Secure checkout',
  },
  tvaIncluded: {
    id: 'go_to_next_tva_included',
    defaultMessage: 'VAT included',
  },
}

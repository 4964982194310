<template>
  <div>
    <div class="flex justify-between">
      <div class="flex flex-col md:flex-row md:items-center">
        <RevIllustration
          alt="BackUp Logo"
          :height="21"
          src="/img/insurances/backUpLogo.svg"
          :width="96"
        />
        <div class="body-1-bold mt-12 md:ml-8 md:mt-0">{{ offer.title }}</div>
      </div>

      <RevButtonTiny
        class="hidden md:block"
        type="submit"
        variant="secondaryDestructive"
        @click="handleRemove"
      >
        {{ i18n(translations.remove) }}
      </RevButtonTiny>
    </div>

    <div class="mt-8 flex items-baseline md:mt-2">
      <div class="body-1-bold">{{ i18n.price(offer.price) }}</div>
    </div>

    <div class="mt-10 flex justify-between">
      <RevLink class="body-2-link" @click="handleInsuranceModalOpen">
        <span class="text-static-default-low">{{
          i18n(translations.learnMore)
        }}</span>
      </RevLink>

      <RevButtonTiny
        class="block md:hidden"
        type="submit"
        variant="secondaryDestructive"
        @click="handleRemove"
      >
        {{ i18n(translations.remove) }}
      </RevButtonTiny>

      <ClientOnly>
        <InsuranceModal :name="modalName" />
      </ClientOnly>
    </div>

    <div class="relative mt-24 md:mt-32">
      <RevCheckbox
        v-if="isAgreementRequired"
        :id="`compliancy-${listingId}`"
        :checked="selectedTerms"
        @change="handleCompliancyCheckboxChange"
      >
        <template #label>
          <Terms :documents="documents" />
        </template>
      </RevCheckbox>

      <Terms v-if="!isAgreementRequired" :documents="documents" />
      <p
        v-if="errorTermsOfUse"
        :id="`errorMessage-${listingId}`"
        class="text-static-danger-hi mt-3"
      >
        {{ i18n(translations.trustpackCGVError) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { cartAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '../../../config'
import { InsuranceModal } from '../InsuranceModal'
import { Terms } from '../Terms'

import translations from './InsuranceSelected.translations'

const props = defineProps<{
  offer: cartAPI.CheckoutInsuranceOffer
  listingId: string
  errorTermsOfUse: boolean
}>()

const i18n = useI18n()

const emit = defineEmits(['remove', 'update-compliancy'])

const selectedTerms = ref(props.offer.compliancy.isUserAgreementAccepted)

onMounted(async () => {
  try {
    const { isAccepted } = await $httpFetch(cartAPI.getInsuranceAgreement, {
      queryParams: {
        listingId: props.listingId,
        insuranceOfferId: props.offer.id,
      },
    })

    selectedTerms.value = isAccepted
  } catch {
    selectedTerms.value = false
  }
})

const modalName = `${MODAL_NAMES.CART_INSURANCE_OFFER_EXPLANATIONS}-${props.listingId}`

const isAgreementRequired = computed(
  () => props.offer.compliancy?.isUserAgreementRequired,
)

const documents = computed(() => props.offer.compliancy?.documents)

const handleInsuranceModalOpen = () => {
  openModal(modalName)
}

const handleCompliancyCheckboxChange = async (event: Event) => {
  const { checked } = event.target as HTMLInputElement

  selectedTerms.value = checked

  try {
    const { isAccepted } = await $httpFetch(cartAPI.postInsuranceAgreement, {
      body: {
        listingId: props.listingId,
        insuranceOfferId: props.offer.id,
        isAccepted: selectedTerms.value,
      },
    })

    selectedTerms.value = isAccepted
  } catch {
    // If POST fails, we return to the state it was before
    selectedTerms.value = !selectedTerms.value
  }

  emit('update-compliancy', {
    id: props.listingId,
    insurance: {
      ...props.offer,
      compliancy: {
        ...props.offer.compliancy,
        isUserAgreementAccepted: selectedTerms.value,
      },
    },
    checked: selectedTerms.value,
  })
}

const handleRemove = () => {
  emit('remove', { id: props.listingId })
}
</script>

<template>
  <div v-if="accessoriesList" role="note">
    <p class="text-static-default-low body-2">
      {{ i18n(translations.comesWith, { accessories: accessoriesList }) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './AccessoriesSummary.translations'

const props = defineProps<{
  accessories: Array<{
    text: string
  }>
}>()

const i18n = useI18n()

const accessoriesList = computed(() => {
  return props.accessories
    .map(({ text }: { text: string }) => text)
    .filter((text: string) => !!text)
    .join(', ')
})
</script>

<template>
  <ShippingOption
    v-for="choice in choices"
    :key="choice.shippingId"
    :checked="choice.selected"
    class="mt-12 first:mt-0"
    :earliest-arrival-date="choice.earliestArrivalDate"
    :is-collection-point="choice.isCollectionPoint"
    :latest-arrival-date="choice.latestArrivalDate"
    :listing-id="listingId"
    :price="getFormattedPrice(choice)"
    :shipper="choice.shipperDisplay"
    :shipping-id="choice.shippingId"
    :show-collection-point-error="showCollectionPointError"
    :title="choice.title"
    @change="checkOption(option, choice)"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { Currency, cartAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import ShippingOption from './ShippingOption.vue'

const emit = defineEmits(['select'])

const props = defineProps<{
  listingId: string
  option: cartAPI.ShippingOption
  showCollectionPointError: boolean
}>()

const i18n = useI18n()

const choices = ref(props.option.choices)

const getFormattedPrice = (choice: cartAPI.ShippingChoice) => {
  if (choice.price === 0) {
    return choice.priceWithCurrency
  }

  return i18n.price({
    amount: `${choice.price}`,
    currency: choice.currency as Currency,
  })
}

const checkOption = async (
  option: cartAPI.ShippingOption,
  choice: cartAPI.ShippingChoice,
) => {
  choices.value = choices.value.map((c) => {
    return {
      ...c,
      selected: choice.shippingId === c.shippingId,
    }
  })

  emit(
    'select',
    {
      listingId: props.listingId,
      optionType: option.type,
      optionId: choice.shippingId,
    },
    choice,
  )
}
</script>

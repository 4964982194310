<template>
  <RevTooltip class="w-60" content-class-names="m-5" position="auto">
    <template #trigger="{ show, hide }">
      <QuantitySelector
        :id="id"
        :disabled
        :error="error"
        :listing-title="listingTitle"
        :max="max"
        :min="1"
        :model-value="modelValue"
        @update:model-value="
          (newValue: number) => onQuantityChange({ newValue, hide, show })
        "
      />
    </template>

    {{ error }}
  </RevTooltip>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTooltip } from '@ds/components/Tooltip'

import translations from './ArticleQuantitySelector.translations'
import QuantitySelector from './QuantitySelector.vue'

const i18n = useI18n()

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  id: string
  listingTitle: string
  max: number
  modelValue: string
  disabled?: boolean
}>()

const previousValidValue = ref(0)
const error = ref('')

function onQuantityChange({
  show,
  hide,
  newValue,
}: {
  show: () => void
  hide: () => void
  newValue: number
}) {
  if (newValue > props.max) {
    error.value = i18n(translations.maxUnitsAvailable, { max: props.max })
    show()
  } else {
    error.value = ''
    hide()
  }

  if (newValue <= props.max && newValue !== previousValidValue.value) {
    previousValidValue.value = newValue
  }
  emit('update:modelValue', newValue)
}
</script>

export default {
  buybackSwapSummaryPriceNew: {
    id: 'buyback_swap_summary_price_new',
    defaultMessage: 'Buyback of {price}',
  },
  buybackSwapSummaryDelete: {
    id: 'buyback_swap_summary_delete',
    defaultMessage: 'Delete my buyback',
  },
  buybackSwapSummaryTitle: {
    id: 'buyback_swap_summary_title',
    defaultMessage: 'About the buyback',
  },
  buybackSwapSummaryTradeInValue: {
    id: 'cart_swap_trade_in_value',
    defaultMessage: 'Trade-in value: {price}',
  },
  buybackSwapSummaryTradeInRemove: {
    id: 'cart_swap_trade_in_remove',
    defaultMessage: 'Remove',
  },
  buybackSwapSummaryTradeInShipping: {
    id: 'cart_swap_ship_instruction',
    defaultMessage: 'Ship within 21 days of trade-in offer.',
  },
  buybackSwapSummaryTradeMailing: {
    id: 'cart_swap_kit_instruction',
    defaultMessage: 'Receive free mailing kit within 4 days.',
  },
  buybackSwapSummaryTradeNoMailing: {
    id: 'cart_swap_label_instruction',
    defaultMessage: 'Download your free shipping label.',
  },
  buybackSwapSummaryTradeInMoney: {
    id: 'cart_swap_get_paid_instruction',
    defaultMessage: 'Get paid after inspection.',
  },
  buybackSwapSummaryTradeInLearnMore: {
    id: 'cart_swap_trade_in_learn_more',
    defaultMessage: 'Learn more',
  },
  buybackSwapSummaryTradeSummaryModalTitle: {
    id: 'cart_trade_in_dialog_navigation_title',
    defaultMessage: 'Your trade-in',
  },
  buybackSwapSummaryTradeSummaryModalBodyTitle: {
    id: 'cart_trade_in_dialog_title',
    defaultMessage: 'Get your savings on today’s purchase',
  },
  buybackSwapSummaryTradeSummaryModalBodyDescription: {
    id: 'cart_trade_in_dialog_subtitle',
    defaultMessage:
      'Complete your trade-in and get {tradein, html} after device inspection. That’ll be like today’s purchase only setting you back {totalprice, html}',
  },
  buybackSwapSummaryTradeSummaryModalDetailsBoxTitle: {
    id: 'cart_trade_in_dialog_kit_title',
    defaultMessage: 'Receive free mailing kit',
  },
  buybackSwapSummaryTradeSummaryModalDetailsBoxLabel: {
    id: 'cart_trade_in_dialog_kit_subtitle',
    defaultMessage: 'Your mailing kit will arrive within 4 days.',
  },
  buybackSwapSummaryTradeSummaryModalDetailsNoBoxTitle: {
    id: 'cart_trade_in_dialog_label_title',
    defaultMessage: 'Download free  label',
  },
  buybackSwapSummaryTradeSummaryModalDetailsNoBoxLabel: {
    id: 'cart_trade_in_dialog_label_subtitle',
    defaultMessage:
      'Print the prepaid shipping label and stick it to your package.',
  },
  buybackSwapSummaryTradeSummaryModalDetailsShippingTitle: {
    id: 'cart_trade_in_dialog_ship_title',
    defaultMessage: 'Ship your trade-in ',
  },
  buybackSwapSummaryTradeSummaryModalDetailsShippingLabel: {
    id: 'cart_trade_in_dialog_ship_subtitle',
    defaultMessage:
      'You’ll have 21 days from today to transfer your data to another device and ship your trade-in.',
  },
  buybackSwapSummaryTradeSummaryModalDetailsMoneyTitle: {
    id: 'cart_trade_in_dialog_cash_title',
    defaultMessage: 'Get cash money',
  },
  buybackSwapSummaryTradeSummaryModalDetailsMoneyLabel: {
    id: 'cart_trade_in_dialog_cash_subtitle',
    defaultMessage: 'Receive a direct deposit within 5 days.',
  },
}

export default {
  remove: {
    id: 'remove_insurance',
    defaultMessage: 'Remove insurance',
  },
  learnMore: {
    id: 'insurance_cart_learn_more_backup',
    defaultMessage: 'what BackUp gets you',
  },
  trustpackCGVError: {
    id: 'trutpack_cgv_error',
    defaultMessage: 'Thanks for accepting the general buying conditions.',
  },
}

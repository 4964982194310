<template>
  <div class="mb-32 w-full md:mb-56 md:flex md:items-start">
    <div class="flex grow items-start overflow-hidden">
      <RevLink
        v-if="shouldDisplayImageLink"
        :aria-label="title"
        target="_blank"
        :to="link"
      >
        <RevIllustration
          :alt="title"
          class="h-60 w-60 max-w-60 cursor-pointer align-top md:h-[110px] md:w-[110px] md:max-w-[110px]"
          :height="60"
          :src="image"
          :width="60"
        />
      </RevLink>

      <RevIllustration
        v-if="!displayLink"
        :alt="title"
        class="h-60 w-60 max-w-60 cursor-pointer align-top md:h-[110px] md:w-[110px] md:max-w-[110px]"
        :height="60"
        :src="image"
        :width="60"
      />

      <div class="ml-12 inline-block md:ml-32">
        <span v-if="hasNoStock" class="text-static-danger-hi body-2-bold">
          {{ i18n(translations.productNotAvailable) }}
        </span>
        <RevLink
          v-if="displayLink"
          class="text-left"
          target="_blank"
          :to="link"
          :underlined="false"
        >
          <div class="body-1-bold">{{ title }}</div>
        </RevLink>
        <div v-if="!displayLink" class="body-1-bold">{{ title }}</div>
        <slot />
      </div>
    </div>
    <slot name="actions" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { LinkInternal } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import translations from './CartProduct.translations'

const props = withDefaults(
  defineProps<{
    displayLink: boolean
    hasNoStock?: boolean
    link: LinkInternal
    image: string
    title: string
  }>(),
  {
    hasNoStock: false,
  },
)

const i18n = useI18n()

const shouldDisplayImageLink = computed(() => {
  return props.image && props.displayLink
})
</script>

export default {
  description: {
    id: 'insurance_catchup_modale_description',
    defaultMessage:
      'Are you sure you don’t want to protect your {deviceName, html} against {damage, html} or {theft, html} for only {price, html}?',
  },
  add: {
    id: 'insurance_catchup_modale_button_add_coverage',
    defaultMessage: 'Go to cart to add coverage',
  },
  continue: {
    id: 'insurance_catchup_modale_button_nocoverage',
    defaultMessage: 'Continue with no coverage',
  },
  damage: {
    id: 'insurance_catchup_modale_damage',
    defaultMessage: 'damage',
  },
  theft: {
    id: 'insurance_catchup_modale_theft',
    defaultMessage: 'theft',
  },
}

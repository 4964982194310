<template>
  <div>
    <RevStickyBar class="md:hidden" :visible="true">
      <div class="flex flex-row items-end">
        <div class="mr-12 flex flex-none flex-col">
          <div class="body-1">
            {{ i18n(translations.stickyButtonTotal) }}
          </div>
          <div class="body-1-bold">{{ totalPriceFormatted }}</div>
        </div>
        <RevButton
          :data-qa="`go-to-next-${placement}`"
          :disabled="disabled"
          full-width="responsive"
          :loading="loading"
          variant="primary"
          @click="$emit('next')"
        >
          {{ i18n(translations.button) }}
        </RevButton>
      </div>
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import { useCartStore } from '../stores/cartStore'
import { useDiscountStore } from '../stores/discountStore'

import translations from './CheckoutNext.translations'

withDefaults(
  defineProps<{
    disabled?: boolean
    loading?: boolean
    placement?: string
  }>(),
  {
    disabled: false,
    loading: false,
    placement: '',
  },
)

defineEmits(['next'])

const marketPlace = useMarketplace()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const i18n = useI18n()

const totalPrice = computed(() =>
  discountStore.isApplied ? discountStore.getDeductedPrice : cartStore.price,
)

const totalPriceFormatted = computed(
  () =>
    `${i18n.price(totalPrice.value)} ${
      marketPlace.features.vatIncluded ? i18n(translations.tvaIncluded) : ''
    }`,
)
</script>
